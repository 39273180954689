<template>
    <div class="d-flex">
        <div class="d-flex flex-column vh-100 py-3 text-white">
            <a href="/home" class="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-white text-decoration-none">
                <span class="fs-4 px-5"><img class="img-fluid" src="@/assets/images/logo.png" /></span>
            </a>
            <ul class="nav flex-column">
                <li class="nav-item mt-5">
                    <router-link to="/home" class="nav-link px-5 py-3" :class="(sidebar == 1) ? 'router-active' : ''">
                        <DashboardIcon :color="(sidebar == 1) ? '#4CB970' : '#aaa'" /> <span
                            class="ps-2 font-size-16 align-middle">Dashboard</span>
                    </router-link>
                </li>
                <li class="nav-item mt-4">
                    <router-link to="/properties" class="nav-link px-5 py-3" :class="(sidebar == 2) ? 'router-active' : ''">
                        <HomeIcon :color="(sidebar == 2) ? '#4CB970' : '#aaa'" /> <span
                            class="ps-2 font-size-16 align-middle">Properties</span>
                    </router-link>
                </li>
                <li class="nav-item mt-4">
                    <router-link to="/reports" class="nav-link px-5 py-3" :class="(sidebar == 3) ? 'router-active' : ''">
                        <ReportIcon :color="(sidebar == 3) ? '#4CB970' : '#aaa'" /> <span
                            class="ps-2 font-size-16 align-middle">Reports</span>
                    </router-link>
                </li>
                <li class="nav-item mt-4">
                    <router-link to="/ranks" class="nav-link px-5 py-3" :class="(sidebar == 4) ? 'router-active' : ''">
                        <StarsIcon :color="(sidebar == 4) ? '#4CB970' : '#aaa'" /> <span
                            class="ps-2 font-size-16 align-middle">Ranks</span>
                    </router-link>
                </li>
                <li class="nav-item mt-4">
                    <router-link to="/settings/personal_information" class="nav-link px-5 py-3" :class="(sidebar == 5) ? 'router-active' : ''">
                        <CogIcon :color="(sidebar == 5) ? '#4CB970' : '#aaa'" /> <span
                            class="ps-2 font-size-16 align-middle">Settings</span>
                    </router-link>
                </li>
            </ul>
            <div class="flex-grow-1"></div>
            <ul class="nav flex-column">
                <li class="nav-item mt-4 p-0">
                    <router-link :to="{ path: '/home', query: { logout: '1' } }" class="nav-link text-decoration-none px-5 py-3">
                        <LogoutIcon /> <span class="ps-2 font-size-16 text-danger align-middle">Logout</span>
                    </router-link>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import CogIcon from '@/components/icons/CogIcon.vue';
import DashboardIcon from '@/components/icons/DashboardIcon.vue';
import ReportIcon from '@/components/icons/ReportIcon.vue';
import StarsIcon from '@/components/icons/StarsIcon.vue';
import LogoutIcon from '@/components/icons/LogoutIcon.vue';
import HomeIcon from '@/components/icons/HomeIcon.vue';

export default {
    name: 'SideBar',
    props: { sidebar: Number },
    components: { DashboardIcon, ReportIcon, StarsIcon, CogIcon, LogoutIcon, HomeIcon },
}
</script>
<style>
.router-active {
    background-color: #F0F5EF !important
}

.router-active span {
    color: #000 !important
}

.nav-link {
    color: #aaa !important
}
.font-size-16{
    color: #2F4F5C;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
</style>