import { createRouter, createWebHistory } from "vue-router";
import AuthLayout from "@/components/layouts/AuthLayout.vue";
import BenchmarkLayout from "@/components/layouts/BenchmarkLayout.vue";

const routes = [
  {
    path: "/",
    component: AuthLayout,
    beforeEnter: (to, from, next) => {
      const token =
        localStorage.getItem("accessToken") ||
        localStorage.getItem("accessToken");
      if (token === null) {
        next();
      } else {
        next("/home");
      }
    },
    children: [
      {
        path: "/gauge",
        component: () => import("@/views/GaugeView.vue"),
        meta: { title: "Gauge" },
      },
      {
        path: "/",
        component: () => import("@/views/auth/LoginView.vue"),
        meta: { title: "Login" },
      },
      {
        path: "/forget",
        component: () => import("@/views/auth/ForgetView.vue"),
        meta: { title: "Reset Password" },
      },
      {
        path: "/forgetByEmail",
        component: () => import("@/views/auth/ForgetByEmail.vue"),
        meta: { title: "Reset Password" },
      },
      {
        path: "/forgetByPhone",
        component: () => import("@/views/auth/ForgetByPhone.vue"),
        meta: { title: "Reset Password" },
      },
      {
        path: "/forgetPasswordChange",
        component: () => import("@/views/auth/ForgetPasswordChange.vue"),
        meta: { title: "Reset Password" },
      },
      {
        path: "/register",
        component: () => import("@/views/auth/RegisterView.vue"),
        meta: { title: "Register" },
      },
      {
        path: "/OTP",
        beforeEnter: (to, from, next) => {
          if (localStorage.getItem("otpToken") === null) {
            next("/register");
          } else {
            next();
          }
        },
        meta: { title: "Register" },
        component: () => import("@/views/auth/OtpView.vue"),
      },
    ],
  },
  {
    path: "/home",
    component: BenchmarkLayout,
    beforeEnter: (to, from, next) => {
      const token =
        localStorage.getItem("accessToken") ||
        localStorage.getItem("accessToken");
      if (token === null) {
        next("/");
      } else {
        next();
      }
    },
    children: [
      {
        path: "/home",
        component: () => import("@/views/benchmark/HomeView.vue"),
        meta: { title: "Dashboard" },
      },
      {
        path: "/properties",
        children: [
          {
            path: "",
            component: () => import("@/views/benchmark/PropertiesView.vue"),
            meta: { title: "Properties" },
          },
          {
            path: "create",
            component: () =>
              import("@/views/benchmark/properties/PropertiesCreate.vue"),
            meta: { title: "Create Property" },
          },
          {
            path: ":id",
            children: [
              {
                path: "",
                component: () =>
                  import("@/views/benchmark/properties/PropertiesDetails.vue"),
                meta: { title: "Property Dashboard" },
              },
              {
                path: "records",
                component: () =>
                  import(
                    "@/views/benchmark/properties/PropertiesDetailsRecords.vue"
                  ),
                meta: { title: "Property Dashboard" },
              },
              {
                path: "static_factors",
                component: () =>
                  import(
                    "@/views/benchmark/properties/PropertiesDetailsStaticFactors.vue"
                  ),
                meta: { title: "Property Dashboard" },
              },
              {
                path: "operations_records",
                component: () =>
                  import(
                    "@/views/benchmark/properties/PropertiesDetailsOperationsRecords.vue"
                  ),
                meta: { title: "Property Dashboard" },
              },
              {
                path: "projects_and_initiatives",
                component: () =>
                  import(
                    "@/views/benchmark/properties/PropertiesDetailsProjectsAndInitiatives.vue"
                  ),
                meta: { title: "Property Dashboard" },
              },
            ],
          },
          {
            path: ":id/edit",
            component: () =>
              import("@/views/benchmark/properties/PropertiesEdit.vue"),
            meta: { title: "Edit Property" },
          },
        ],
      },
      {
        path: "/settings",
        children: [
          {
            path: "personal_information",
            component: () =>
              import("@/views/benchmark/settings/PersonalInformationView.vue"),
            meta: { title: "Settings" },
          },
          {
            path: "change_password",
            component: () =>
              import("@/views/benchmark/settings/ChangePasswordView.vue"),
            meta: { title: "Settings" },
          },
        ],
      },
      {
        path: "/ranks",
        component: () => import("@/views/benchmark/RanksView.vue"),
        meta: { title: "Ranks" },
      },
      {
        path: "/reports",
        component: () => import("@/views/benchmark/ReportsView.vue"),
        meta: { title: "Reports" },
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title + " | Mieyar" || "Mieyar";
  next();
});

export default router;
