<template>
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb m-0">
            <!-- Use v-if to check if the current breadcrumb is not the last one -->
            <template v-for="(crumb, index) in breadcrumbs" :key="index">
                <li class="breadcrumb-item text-capitalize" :class="{ active: index === breadcrumbs.length - 1 }">
                    <span class="item text-decoration-none pe-2" :class="{ active: index === breadcrumbs.length - 1 }" v-if="crumb.path === 'none'">{{ crumb.text }}</span>
                    <router-link class="item text-decoration-none pe-2" :class="{ active: index === breadcrumbs.length - 1 }" v-else-if="index < breadcrumbs.length - 1"
                        :to="{ path: crumb.path }">
                        {{ crumb.text }}
                    </router-link>
                    <span class="item text-decoration-none pe-2" :class="{ active: index === breadcrumbs.length - 1 }" v-else>{{ crumb.text }}</span>
                    <ArowIcon v-if="index < breadcrumbs.length - 1" />
                </li>
            </template>
        </ol>
    </nav>
</template>

<script>
import ArowIcon from '@/components/icons/ArowIcon.vue';

export default {
    name: 'BreadCrumb',
    props: { breadcrumbs: Array },
    components: {
        ArowIcon,
    },
};
</script>

<style scoped>
.item {
    color: #D2D2D2;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.387px;
}

.active {
    color: #2f4f5c !important;
}
.breadcrumb-item+.breadcrumb-item::before {
    float: left;
    padding-right: var(--bs-breadcrumb-item-padding-x);
    color: var(--bs-breadcrumb-divider-color);
    content: var(--bs-breadcrumb-divider, "");
}
</style>
