<template>
    <div class="container-fluid">
        <div class="row min-vh-100 min-vw-100">
            <GifLoading />
            <div class="col-md-2 col-lg-2 d-md-block bg-light sidebar collapse text-start p-0">
                <SideBar :sidebar="sidebar" />
            </div>
            <div class="col-md-10 ms-sm-auto col-lg-10 px-md-0 p-0">
                <NavBar :breadcrumbs="breadcrumbs" :profileImage="profileImage" />
                <div class="ps-4">
                    <SuccessToast />
                    <SuccessModal />
                    <SharePropertyModal />
                    <ErrorModal />
                    <router-view @change-breadcrumbs="updateBreadcrumbs" @change-sidebar="updateSidebar" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SideBar from '@/components/layouts/partials/SideBar.vue';
import NavBar from '@/components/layouts/partials/NavBar.vue';
import SuccessModal from '@/components/modals/SuccessModal.vue';
import SharePropertyModal from '@/components/modals/SharePropertyModal.vue';
import ErrorModal from '@/components/modals/ErrorModal.vue';
import GifLoading from '@/components/loading/GifLoading.vue';
import { ApiService } from '@/Services/ApiService';
import { eventBus } from '@/events/eventBus';
import SuccessToast from '@/components/toasts/SuccessToast.vue';

export default {
    name: "AuthLayout",
    components: { SideBar, NavBar, SuccessModal, ErrorModal, GifLoading, SuccessToast, SharePropertyModal },
    mounted() {
        this.fetchData()
    },
    data() {
        return {
            api: new ApiService(),
            breadcrumbs: [],
            sidebar: 1,
            profileImage: '',
        }
    },
    methods: {
        updateBreadcrumbs(newBreadcrumbs) {
            this.breadcrumbs = newBreadcrumbs;
        },
        updateSidebar(id) {
            this.sidebar = id;
        },
        async fetchData() {
            try {
                eventBus.emit('show-loading', true);
                this.result = await this.api.get('clients/info');
                this.profileImage = this.result.data.image;
                localStorage.setItem('client_name', this.result.data.first_name + ' ' + this.result.data.last_name);
                localStorage.setItem('client_email', this.result.data.email);
                localStorage.setItem('country_id', this.result.data.country.id);
                localStorage.setItem('country_name', this.result.data.country.name);
                localStorage.setItem('country_currency', this.result.data.country.currency);
                eventBus.emit('show-loading', false);
            } catch (err) {
                this.error = err.message;
            }
        }
    }
};
</script>