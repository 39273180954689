<template>
    <form @submit.prevent="submit">
        <div class="input-group">
            <span class="input-group-text bg-white border-0">
                <SearchIcon />
            </span>
            <input class="form-control border-0 px-0" placeholder="Search any keywords" style="background-color: white"
                v-model="formData.search" name="email" type="text" id="email" required />
        </div>
    </form>
</template>
<script>
import SearchIcon from '@/components/icons/SearchIcon.vue';

export default {
    name: 'SearchForm',
    data() {
        return {
            formData: {
                search: ''
            }
        };
    },
    methods: {
        submit() {
        }
    },
    components: { SearchIcon }
}
</script>